/**
 * @typedef EventMap
 * @property {() => any} ready
 * 
 */

/**
 * @template {EventMap} T
 * @typedef {keyof T} Event
 */

/**
 * @template {EventMap} T
 * @template {Event<T>} E
 * @typedef {T[E] extends (...args: any) => any ? T[E] : never} Listener
 */

/**
 * @template L
 * @typedef {L extends (...args: infer P) => any ? P : never} Parameters
 */

/**
 * @template L
 * @typedef {L extends (...args: any) => infer R ? R : never} Returns
 */

/**
 * @template {EventMap} T
 * @typedef {T extends undefined ? (...args) => any : never} DefaultListener
 */

/* Polyfill EventEmitter. */
/**
 * @template {EventMap} [T=undefined]
 */
class EventEmitter {
  splitEvents(events) {
    return typeof events == 'string' ? events.split(' ') :
      events instanceof Array ? events :
        [events];
  }

  constructor() {
    this.events = {};
    this.isReady = false;
  }

  /**
   * @template {Event<T>} E
   * @overload
   * @param {E} event
   * @param {Listener<T, E>} listener
   *//**
   * @overload
   * @param {string} event
   * @param {DefaultListener<T>} listener
   * @returns 
   */
  on(events, ...listeners) {
    for (let event of this.splitEvents(events)) {
      for (let listener of listeners) {
        if (typeof this.events[event] !== 'object') {
          this.events[event] = [];
        }
        this.events[event].push(listener);
      }
    }

    return this;
  }

  /**
   * @template {Event<T>} E
   * @overload
   * @param {E} event
   * @param {Listener<T, E>} listener
   *//**
   * @overload
   * @param {string} event
   * @param {DefaultListener<T>} listener
   * @returns 
   */
  onFirst(events, ...listeners) {
    for (let event of this.splitEvents(events)) {
      for (let listener of listeners) {
        if (typeof this.events[event] !== 'object') {
          this.events[event] = [];
        }
        this.events[event].unshift(listener);
      }
    }

    return this;
  }

  /**
   * @template {Event<T>} E
   * @overload
   * @param {E} events 
   * @param {T[E]} listener 
   * @returns 
   *//**
   * @overload
   * @param {string} events
   * @param {DefaultListener<T>} listener
   * @returns
   */
  removeListener(events, listener) {
    for (let event of this.splitEvents(events)) {
      var idx;
      if (typeof this.events[event] === 'object') {
        idx = this.events[event].indexOf(listener);

        if (idx > -1) {
          this.events[event].splice(idx, 1);
        }
      }
    }

    return this;
  }

  /**
   * @template {T extends undefined ? string : Event<T>} E
   * @param {T extends undefined ? string : E} events 
   * @param  {T extends undefined ? any[] : Parameters<T[E]>} args 
   * @returns {Promise<T extends undefined ? any : Returns<T[E]>>}
   */
  async emit(events, ...args) {
    for (let event of this.splitEvents(events)) {
      if (this.events[event] instanceof Array) {
        for (let listener of [...this.events[event]]) {
          let result = await listener.apply(this, args);

          if (result != null) {
            return result;
          }
        }
      }
    }
  }

  /**
   * @template {Event<T>} E
   * @overload
   * @param {E} event
   * @param {Listener<T, E>} listener
   *//**
   * @overload
   * @param {string} event
   * @param {DefaultListener<T>} listener
   * @returns 
   */
  once(events, ...listeners) {
    for (let event of this.splitEvents(events)) {
      for (let listener of listeners) {
        this.on(event, function g() {
          this.removeListener(event, g);
          return listener.apply(this, arguments);
        });
      }
    }

    return this;
  }

  /**
   * @template {Event<T>} E
   * @overload
   * @param {E} event
   * @param {Listener<T, E>} listener
   *//**
   * @overload
   * @param {string} event
   * @param {DefaultListener<T>} listener
   * @returns 
   */
  onceFirst(events, ...listeners) {
    for (let event of this.splitEvents(events)) {
      for (let listener of listeners) {
        this.on(event, function g() {
          this.removeListener(event, g);
          return listener.apply(this, arguments);
        });
      }
    }

    return this;
  }


  /**
   * @overload
   * @param {T extends undefined ? () => void : Listener<T, 'ready'>} listener
   *//**
   * @overload
   * @param {boolean} ready
   * @returns {void}
   *//**
   * @overload
   * @returns {Promise<void>}
   */
  ready(...listeners) {
    if (typeof listeners[0] === 'boolean' ) {
      this.isReady = listeners[0];
      if(listeners[0] === true) {
        this.emit('ready');
      }
    }
    else {
      return new Promise(async resolve => {
        if (this.isReady) {
          for (let listener of listeners) {
            let result = listener.apply(this);
            if (result instanceof Promise) await result;
          }
          resolve();
        } else {
          this.once('ready', resolve, ...listeners);
        }
      });
    }
  }
}

window.EventEmitter = EventEmitter;
module.exports = EventEmitter;